<template>
  <b-container class="main" fluid>
    <b-row fluid style="min-height: 100vh">
      <b-button
        size="sm"
        class="side-menu-button"
        @click="showSideBar = true"
        variant="dark"
        :style="showSideBar ? 'display:none' : 'display:block'"
        style="max-width: 100px"
      >
        Show Menu
      </b-button>
      <b-col
        :class="showSideBar ? 'show' : 'hide'"
        class="side-menu pt-5"
        cols="3"
      >
        <img class="foto-profil" alt="Vue logo" src="../assets/fotoIlham.jpg" />
        <div id="name" class="mt-3">M Abdurrahman Ilham</div>
        <div id="socialMedia" class="mt-3">
          <a href="https://twitter.com" target="_blank">
            <b-avatar icon="twitter" class="sosmed"> </b-avatar>
          </a>
          <a href="https://facebook.com" target="_blank">
            <b-avatar icon="facebook" class="sosmed"> </b-avatar>
          </a>
          <a href="https://linkedin.com" target="_blank">
            <b-avatar icon="linkedin" class="sosmed"> </b-avatar>
          </a>
          <a href="https://youtube.com" target="_blank">
            <b-avatar icon="youtube" class="sosmed"> </b-avatar>
          </a>
        </div>
        <b-container class="navigation mt-5">
          <div
            :class="index === 0 ? 'nav-item selected-class' : 'nav-item'"
            @click="index = 0"
          >
            <b-icon-house /> Home
          </div>
          <div
            :class="index === 1 ? 'nav-item selected-class' : 'nav-item'"
            @click="index = 1"
          >
            <b-icon-person /> About
          </div>
          <div
            :class="index === 2 ? 'nav-item selected-class' : 'nav-item'"
            @click="index = 2"
          >
            <b-icon-file-earmark /> Resume
          </div>
          <div
            :class="index === 3 ? 'nav-item selected-class' : 'nav-item'"
            @click="index = 3"
          >
            <b-icon-envelope /> Contact
          </div>
        </b-container>
        <b-button
          size="sm"
          class="side-menu-button"
          @click="showSideBar = false"
          variant="dark"
        >
          Hide Menu
        </b-button>
      </b-col>
      <b-col class="main-menu">
        <span v-if="index === 0">
          <div class="nama-atas">M Abdurrahman Ilham</div>
          <div class="nama-bawah">
            Halo semuanya! nama saya ilham, saya adalah seorang web developer
            !!!
          </div>
        </span>
        <span v-if="index === 1">
          <div class="nama-atas">About</div>
          <div>Coming soon!</div>
        </span>
        <span v-if="index === 2">
          <div class="nama-atas">Resume</div>
          <div>Coming soon!</div>
        </span>
        <span v-if="index === 3">
          <div class="nama-atas">Contact</div>
          <div>Coming soon!</div>
        </span>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "MainPage",
  // components:{

  // },
  data: function () {
    return {
      index: 0,
      showSideBar: true,
    };
  },
};
</script>

<style lang="less" scoped>
.main {
  color: white;
}
.side-menu {
  background-color: #0c121f;
  min-height: 100%;
  @media (max-width: 768px) {
    position: absolute;
    width: 250px;
  }
}
.main-menu {
  background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
    url("../assets/bayuSaptaji.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.foto-profil {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 7px solid #17243b;
}
#name {
  font-weight: bolder;
  font-size: 25px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
}
.sosmed {
  background-color: #17243b;
  margin-right: 1%;
  margin-left: 1%;
  padding: 3px;
}
.navigation {
  text-align: start;
}
.nav-item {
  padding-bottom: 15px;
  padding-top: 15px;
  padding-left: 40px;
}
.nav-item:hover {
  background-color: #17243b;
  font-weight: bold;
}
.selected-class {
  background-color: #17243b;
  font-weight: bold;
}
.nama-atas {
  font-size: 35px;
  font-weight: 900;
  padding-top: 250px;
}
a {
  color: white;
}
.side-menu-button {
  position: absolute;
  top: 7px;
  left: 7px;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
}
.hide {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
}
.show {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 300ms;
}
</style>
